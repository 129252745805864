import React from 'react'
import "../pages/Home.css"
import { Button } from 'react-bootstrap'
import { Link } from 'gatsby';
import Wave from "../images/wave.png"
import "./home.css"


function Homepage() {
    return (
        <div className="bg-img">
            <section className="paragraph">
                <h3 className="hashtag">#EC HORSES</h3>
                <p className="text">Breeding, training, trading, and <br /> scouting of talented jumpers in the <br /> center of equestrian sport in Europe.</p>
                {/* <Button className="homeBtn" href="/About">See Sport Horses</Button> */}
                <Link className="homeBtn" to="/sport-horses">See Sport Horses</Link>
            </section>
            <img src={Wave} alt="wave" id="wave-home"/>
        </div>
    )
}

export default Homepage
